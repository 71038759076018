import { Analytics } from '@vercel/analytics/react'
import { SpeedInsights } from '@vercel/speed-insights/next'
import type { NextPage } from 'next'
import { UserConfig, appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import dynamic from 'next/dynamic'
import type { ReactElement, ReactNode } from 'react'
import React from 'react'
import { rubik } from 'src/constants/fonts'
import nextI18NextConfig from '../next-i18next.config.js'
import '../public/styles/global.css'
import config from '../src/config'

const AllThemeProviders = dynamic(
  () => import('../src/components/ThemeProvider')
)
const CurrencyAndLanguageProvider = dynamic(
  () => import('../src/context/CurrencyAndLanguageProvider')
)
const LoggerProvider = dynamic(() => import('../src/context/LoggerProvider'))
const LoaderProvider = dynamic(() => import('../src/context/LoaderProvider'))
const ApolloProvider = dynamic(() => import('../src/context/ApolloProvider'))
const SnackbarProvider = dynamic(() => import('../src/context/SnackProvider'))
const Scripts = dynamic(() => import('../src/components/Scripts'))
const AuthProvider = dynamic(
  () => import('../src/context/AuthProvider/provider')
)
const ErrorBoundary = dynamic(() => import('../src/components/ErrorBoundary'))
const UTMs = dynamic(() => import('../src/components/UTMs'))

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { mode, debug }: { mode: any; debug: boolean } = config.isProd
  ? { mode: 'production', debug: false }
  : { mode: 'development', debug: true }

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getLayout?: (page: ReactElement, pageProps: any) => ReactNode
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

const emptyInitialI18NextConfig: UserConfig = {
  i18n: {
    defaultLocale: nextI18NextConfig.i18n.defaultLocale,
    locales: nextI18NextConfig.i18n.locales
  }
}

function MyApp(props: AppPropsWithLayout) {
  const { Component, pageProps } = props
  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${rubik.style.fontFamily};
        }
      `}</style>

      <AllThemeProviders>
        <LoggerProvider>
          <ApolloProvider>
            <ErrorBoundary>
              <LoaderProvider>
                <SnackbarProvider>
                  <AuthProvider>
                    <CurrencyAndLanguageProvider>
                      {getLayout(<Component {...pageProps} />, pageProps)}

                      <UTMs />
                      <Scripts />
                      {config.isProd && (
                        <>
                          <Analytics mode={mode} debug={debug} />
                          <SpeedInsights debug={debug} />
                        </>
                      )}
                    </CurrencyAndLanguageProvider>
                  </AuthProvider>
                </SnackbarProvider>
              </LoaderProvider>
            </ErrorBoundary>
          </ApolloProvider>
        </LoggerProvider>
      </AllThemeProviders>
    </>
  )
}

export default appWithTranslation(React.memo(MyApp), emptyInitialI18NextConfig)
